(function ($, undefined) {
    "use strict";
    var drop_vm_actins = function(){
        var menu = $('#vm-menu'),
            drop = [],
            items = menu.data('items') || menu.children('li'),
            avb_width = menu.width() - 60,
            avb_num = 4; //max 4, otherwise icon

        menu.data('items', items);
        menu.append(items);
        menu.find('.vm-menu-dropdown').remove();

        if(window.toggleVmActionDrop !== undefined && window.toggleVmActionDrop === false){
            return;
        }

        items.each(function () {
            var self = $(this),
                width = self.width();

            if(avb_num < 1 || avb_width - width < 0 || width < 50){
                drop.push(self.detach());
            }else{
                avb_width -= width;
                avb_num -= 1
            }
        });

        if(!drop.length){
            return true;
        }

        var dropdown = $('<li class="vm-menu-dropdown">' +
            '<a href="#" class="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="true"></a>' +
            '<ul class="dropdown-menu dropdown-menu-right"></ul>' +
            '</li>');

        dropdown.find('.dropdown-menu').append(drop);
        menu.append(dropdown);
    };
    function debounce(func){
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func,100, event);
        };
    }

    $(document).on('cloud.loaded.actions', drop_vm_actins);
    $(window).on('resize', debounce(drop_vm_actins));

})(jQuery);